$(function() {
    // Mobile menu
    $(".nav__hamburger").click(function (){
        event.preventDefault();
        $(this).toggleClass("active");
        $(".nav").toggleClass("show");
    });
    
    // Slider where buy pharmacy
    $(".section-pharmacy-buy__slider .slick-container").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    });
    
    // Scroll up
    $(".footer__scroll-up button").click(function () {
        $("html, body").animate({ scrollTop: 0 }, 500);
        return false;
    });
    
});
